// "babel-polyfill": "^6.26.0",
// import 'babel-polyfill';
let top = {};

top = {
  move_fish: () => {
    const targets = document.querySelectorAll(
      '#header__fishes .header__fish-mask'
    );
    let display_pagetop = true;

    // init
    setTimeout(() => {
      targets[0].style.left = '100px';
    }, 1000);
    setTimeout(() => {
      targets[1].style.right = '100px';
    }, 2000);
    setTimeout(() => {
      targets[2].style.right = '100px';
    }, 3000);
    setTimeout(() => {
      targets[3].style.right = '100px';
    }, 4000);
    setTimeout(() => {
      targets[4].style.left = '100px';
    }, 5000);
    setTimeout(() => {
      targets[5].style.right = '100px';
    }, 5000);

    // add event
    for (const _this of targets) {
      _this.addEventListener('transitionend', () => {
        if (display_pagetop) {
          let current = [].slice.call(targets).indexOf(_this);
          let container = document.querySelector('#header__fishes .container');

          if (current === 0 || current === 4) {
            let offset = Math.round(_this.getBoundingClientRect().left);
            swim_fish(_this, offset, 'left', container);
          } else {
            let offset = _this.getBoundingClientRect().right;
            swim_fish(_this, offset, 'right', container);
          }
        }
      });
    }

    function swim_fish (element, element_offset, way, container) {
      let window_width = window.innerWidth;

      if (way === 'left') {
        let result = window_width - element_offset;
        if (result > 0) {
          let now = parseInt(element.style.left, 10);
          element.parentElement.style.opacity = 1;
          element.style.left = 100 + now + 'px';
        } else {
          let container_width = container.clientWidth;
          let margin =
            container_width < window_width
              ? (window_width - container_width) / 2
              : 0;
          element.parentElement.style.opacity = 0;
          element.parentElement.style.left = -(element.clientWidth + margin) + 'px';
          element.style.left = 0;
        }
      } else {
        if (element_offset > 0) {
          let now = parseInt(element.style.right, 10);
          element.parentElement.style.opacity = 1;
          element.style.right = 100 + now + 'px';
        } else {
          let container_width = container.clientWidth;
          let margin =
            container_width < window_width
              ? (window_width - container_width) / 2
              : 0;
          element.parentElement.style.opacity = 0;
          element.parentElement.style.right = -(element.clientWidth + margin) + 'px';
          element.style.right = 0;
        }
      }
    }
  }
};

// document.addEventListener('DOMContentLoaded', () => {
// });

window.addEventListener('load', () => {
  if (location.hash !== '#noanimation') {
    top.move_fish();
  }
});
